<template>
  <div class="h-screen flex w-full vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Connexion</h4>
                </div>

                <form id="form"  name="login" method="POST" action="#" >
                  <div>
                    <vs-input
                      name="username"
                      icon-no-border
                      icon="icon icon-user"
                      icon-pack="feather"
                      label-placeholder="Email"
                      v-model="form.username"
                      class="w-full"
                    />

                    <vs-input
                      type="password"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Password"
                      v-model="form.password"
                      class="w-full mt-6"
                    />

                    <vs-button
                      class="float-right backgroudRose"
                      :disabled="this.loading"
                      id="submit-button"
                      ref="submitButton"
                      @click="handleConnect"
                    >
                      <template v-if="!loading">Se connecter</template>
                      <hollow-dots-spinner
                        v-else
                        :animation-duration="1000"
                        :dot-size="15"
                        :dots-num="3"
                        color="#fff"
                      />
                    </vs-button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { HollowDotsSpinner } from 'epic-spinners';

const API_URL = process.env.VUE_APP_BASE_URL;

export default {
  name: 'LoginPage',
  components: {
    HollowDotsSpinner,
  },
  props: {
    token: { type: String, default: () => null },
    username: { type: String, default: () => null },
    refresh_token: { type: String, default: () => null },
  },
  data() {
    return {
      loading: false,
      form: {
        username: '',
        password: '',
      },
    };
  },
  async mounted() {
    this.urlConnect();
  },
  methods: {
    /**
     * Récupère et stock les informations de connection depuis l'url
     */
    async urlConnect() {
      if (this.token && this.username && this.refresh_token) {
        const credentials = {
          token: this.token,
          username: this.username,
          refresh_token: this.refresh_token,
        };

        this.$vs.loading('point');
        await this.setUser(credentials);
        this.$vs.loading.close();
      }
    },

    /**
     * Récupère les token de connections utilisateur si les identifiant passés sont bon
     */
    async handleConnect() {
      this.loading = true;

      const body = new FormData();
      body.append('username', this.form.username);
      body.append('password', this.form.password);

      try {
        const { data } = await axios.post(`${API_URL}/login_check`, body);
        await this.setUser({
          ...data,
          username: this.form.username,
        });
      } catch (error) {
        if (error.response.status === 401) {
          this.$toast.error('Identifiants incorrects, veuillez réessayer');
        } else {
          this.$toast.error('Une erreur s\'est produite lors de la connexion, veuillez réessayer');
        }
      } finally {
        this.loading = false;
      }
    },

    /**
     * @TODO: Definir le role depuis le /me pour permettre le impersonnate
     * ↳ ROLE_ALLOWED_TO_SWITCH
     * ↳ x-switch-user
     *
     * Stock les tokens dans le localstorage et récupère les informations utilisateur.
     *
     * @param {Object} credentials
     * @param {String} credentials.token
     * @param {String} credentials.username
     * @param {String} credentials.refresh_token
     */
    async setUser({ token, refresh_token: refreshToken, username }) {
      const { roles } = jwt.decode(token);
      let routeName = 'user-home';

      if (roles.includes('ROLE_ADMIN')) {
        routeName = 'admin-dashboard';
      }

      localStorage.setItem('access_token', token);
      localStorage.setItem('refresh_token', refreshToken);
      localStorage.setItem('email', username);
      localStorage.setItem('userRole', roles);

      try {
        const { data } = await this.$http.get('/api/me');

        this.$store.commit('UPDATE_USER_INFO', {
          email: username,
          user: data,
          userRole: roles,
        });

        this.$store.dispatch('getFormulas');

        this.$router.push({ name: routeName });
      } catch (error) {
        this.$toast.error('Une erreur s\'est produite lors de la connexion');
      }
    },
  },
};
</script>

<style lang="scss">
#page-login {
  h4 {
    float: none;
    padding-left: 0;
  }

  .login-tabs-container {
    overflow: hidden
  }

  button {
    margin-top: 30px
  }

  .align-right {
    text-align: right
  }

  .margin-15 {
    margin-top: 15px
  }

  .backgroudRose {
    background:  #ed1451;
  }

  .full-page-bg-color {
    background: #eff2f7
  }
}

.vs-popup {
  width: 1200px !important;
}
</style>
